import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    userId: 0,
    skills: [],
    postedData: {},
    questions: [],
    questionsNumber: 0,
    postedAnswers: {},
    skillsLoader: true,
    questionsLoader: false,
    isQuestionsCompleted: false,
  },
  getters: {
    getSkills: (state) => state.skills,
    getSkillsLoader: (state) => state.skillsLoader,
    getQuestions: (state) => state.questions,
    getQuestionNumber: (state) => state.questionsNumber,
    getQuestionsLoader: (state) => state.questionsLoader,
    getIsQuestionsCompleted: (state) => state.isQuestionsCompleted,
    getUserId: (state) => state.userId,
  },
  mutations: {
    setSkills(state, payload) {
      state.skills = payload;
    },
    setPostedData(state, payload) {
      state.postResponse = payload;
    },
    setQuestions(state, payload) {
      state.questions = payload;
    },
    setPostedAnswers(state, payload) {
      state.postedAnswers = payload;
    },
    incrementQuestionNumber(state) {
      state.questionsNumber++;
    },
    decrementQuestionNumber(state) {
      state.questionsNumber--;
    },
    testCompleted(state) {
      state.isQuestionsCompleted = true;
    },
    setUserId(state, payload) {
      console.log(payload);
      state.userId = payload;
    },
  },
  actions: {
    async getSkills({ commit }, vid) {
      try {
        this.state.skillsLoader = true;
        const url = `https://verification-api.getmegit.com/skills/${vid}`;

        const response = await axios.get(url);
        commit("setSkills", response.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.state.skillsLoader = false;
      }
    },
    async postSkills({ commit }, form) {
      try {
        const url = `https://verification-api.getmegit.com/verify-skill`;

        const response = await axios.post(url, form, {
          required: true,
          headers: {
            "Content-Type": "application/json",
          },
        });
        commit("setPostedData", response.data);
      } catch (error) {
        console.error("Error posting skills:", error);
      }
    },

    async getQuestions({ commit }, vid) {
      try {
        this.state.questionsLoader = true;
        const url = `https://verification-api.getmegit.com/questions/${vid}`;

        const response = await axios.get(url);
        commit("setQuestions", response.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.state.questionsLoader = false;
      }
    },

    async postAnswers({ commit }, form) {
      try {
        const url = `https://verification-api.getmegit.com/submit-answer`;

        const response = await axios.post(url, form, {
          required: true,
          headers: {
            "Content-Type": "application/json",
          },
        });
        commit("setPostedAnswers", response.data);
      } catch (err) {
        console.error(err);
      }
    },
  },
  modules: {},
});
