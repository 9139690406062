<template>
  <div class="test">
    <template v-if="!getQuestionsLoader">
      <div class="progress-box" v-if="!getIsQuestionsCompleted">
        {{ getQuestionNumber + 1 }} из {{ getQuestions.length }}
        <Progress
          :percent="progressPercent"
          :show-info="false"
          :stroke-color="'#87cc89'"
          trailColor="white"
          class="progress-box__progress"
        />
      </div>
      <div v-if="!getIsQuestionsCompleted && getQuestions.length > 0">
        <template v-for="(question, index) in getQuestions" :key="index">
          <TestExam
            v-if="getQuestionNumber === index"
            @send-test="isTestComplete = true"
            :questions="question"
            :getQuestionsCount="getQuestions.length"
            :questionNumber="index + 1"
          />
        </template>
      </div>

      <TestSuccess v-if="getIsQuestionsCompleted" />
    </template>

    <div v-else class="spin">
      <Spin size="large" />
    </div>
  </div>
</template>

<script setup>
import TestExam from "@/components/test/TestExam.vue";
import TestSuccess from "@/components/test/TestSuccess.vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Spin, Progress } from "ant-design-vue";
import { useRoute } from "vue-router";

const route = useRoute();
const store = useStore();
const isTestComplete = ref(false);

const getIsQuestionsCompleted = computed(() => {
  return store.getters["getIsQuestionsCompleted"];
});

const getQuestions = computed(() => {
  return store.getters["getQuestions"];
});

const getQuestionNumber = computed(() => {
  return store.getters["getQuestionNumber"];
});

const getQuestionsLoader = computed(() => {
  return store.getters["getQuestionsLoader"];
});

const progressPercent = computed(() => {
  if (getQuestions.value.length === 0) return 0;
  return ((getQuestionNumber.value + 1) / getQuestions.value.length) * 100;
});

onMounted(async () => {
  await store.dispatch("getQuestions", route.params.vid);
});
</script>

<style scoped lang="scss">
.progress-box {
  padding: 0 20px;
  margin-top: 80px;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #333;
}
.test {
  width: 100%;
  min-height: calc(100vh);
  background-color: #edeffa;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spin {
  margin-top: 80px;
}
</style>
