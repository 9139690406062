<template>
  <div class="test-skills">
    <div class="test-skills__description-box">
      <p class="test-skills__description-box-title">Навыки</p>
      <p class="test-skills__description-box-description">
        В тесте указаны навыки из вашего резюме: вам необходимо указать уровень
        и длительность владения этими навыками
      </p>
    </div>
    <div class="test-skills__section-box section-box">
      <div
        class="section-box__skills"
        v-for="(skill, index) in form.verifed_skills"
        :key="index"
      >
        <p class="section-box__skills-skill">{{ skill.skill_name }}</p>
        <FormItem
          label="Уровень владения"
          :labelCol="{ span: 24 }"
          :wrapperCol="{ span: 24 }"
        >
          <RadioGroup v-model:value="skill.skill_level" :style="radioStyle">
            <Radio :value="1">Очень плохо</Radio>
            <Radio :value="2">Плохо</Radio>
            <Radio :value="3">Средне</Radio>
            <Radio :value="4">Хорошо</Radio>
            <Radio :value="5">Очень Хорошо</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="Длительность владения в годах"
          :labelCol="{ span: 24 }"
          :wrapperCol="{ span: 24 }"
          class="duration-field"
        >
          <Button
            @click="decrement(skill)"
            size="large"
            class="section-box__skills-leftAddon"
            >-</Button
          >
          <InputNumber
            :controls="false"
            placeholder="1"
            v-model:value="skill.skill_duration"
            class="section-box__skills-duration"
            size="large"
          />
          <Button
            @click="increment(skill)"
            size="large"
            class="section-box__skills-rightAddon"
            >+</Button
          >
        </FormItem>
      </div>
      <Button
        type="primary"
        size="large"
        class="section-box__action"
        @click="nextPage"
        >Продолжить</Button
      >
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  FormItem,
  RadioGroup,
  Radio,
  InputNumber,
  Button,
} from "ant-design-vue";
import { defineProps } from "vue";

const store = useStore();
const router = useRouter();
const route = useRoute();

const props = defineProps({
  data: {
    type: Array,
    default: () => {},
  },
});

const form = ref({
  vacancy_id: route.params.vid,
  developer_id: +route.params.did,
  verifed_skills: [],
});
for (let i = 0; i < props.data?.length; i++) {
  form.value.verifed_skills.push({
    skill_name: props.data[i],
    skill_level: 0,
    skill_duration: 0,
  });
}

const radioStyle = computed(() => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  };
});

const increment = (skill) => {
  skill.skill_duration += 1;
};

const decrement = (skill) => {
  skill.skill_duration = Math.max(0, skill.skill_duration - 1);
};

const nextPage = async () => {
  store.state.userId = +route.params.did;
  await store.dispatch("postSkills", form.value);
  await router.push(`/questions/${route.params.vid}`);
};
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0;
}
.test-skills {
  margin-top: 56px;
  max-width: 600px;
  width: 100%;
  color: #333;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  &__description-box {
    &-title {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      padding-bottom: 4px;
    }
    &-description {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
.section-box {
  width: 100%;
  padding: 20px 24px 24px 24px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  &__skills {
    &-skill {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    &-duration {
      max-width: 54px;
      border-radius: 0;
    }
    &-leftAddon {
      border-radius: 10px 0 0 10px;
    }
    &-rightAddon {
      border-radius: 0 10px 10px 0;
    }
  }
  &__action {
    align-self: center;
    max-width: 160px;
    width: 100%;
    background-color: #5e99ff;
    @media (max-width: 480px) {
      max-width: none;
      width: 100%;
    }
  }
}

.addons {
  cursor: pointer;
  padding: 9px 12px;
}
.decrement {
  font-size: 20px;
}

:deep(.ant-form-item-label) {
  padding: 0 0 4px;
}
:deep(.ant-input-number-input) {
  text-align: center;
}
:deep(.ant-form-item-control-input-content) {
  display: flex;
  align-items: center;
}
</style>
