<template>
  <div class="test">
    <VerificationSkills
      :data="getSkills.skills"
      @show-exam="isShowExam"
      v-if="!exam && !testSuccess && !getSkillsLoader"
    />
    <div v-if="getSkillsLoader" class="test__loader">
      <Spin size="large" />
    </div>
  </div>
</template>

<script setup>
import VerificationSkills from "@/components/test/VerificationSkills.vue";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { Spin } from "ant-design-vue";

const store = useStore();
const route = useRoute();

const exam = ref(false);
const testSuccess = ref(false);

const getSkills = computed(() => {
  return store.getters["getSkills"];
});
const getSkillsLoader = computed(() => {
  return store.getters["getSkillsLoader"];
});
const isShowExam = () => {
  exam.value = true;
};

onMounted(async () => {
  await store.dispatch("getSkills", route.params.vid);
});
</script>

<style scoped lang="scss">
.test {
  width: 100%;
  min-height: calc(100vh);
  background-color: #edeffa;
  display: flex;
  justify-content: center;
  &__loader {
    margin-top: 80px;
  }
}
</style>
