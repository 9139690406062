import { createRouter, createWebHistory } from "vue-router";
import VerificationView from "@/views/VerificationView.vue";
import TestSkillsView from "@/views/TestSkillsView.vue";

const routes = [
  {
    path: "/:vid/:did",
    name: "verification",
    component: VerificationView,
  },
  {
    path: "/questions/:vid",
    name: "test",
    component: TestSkillsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
