<template>
  <div class="test-success">
    <div class="test-success__description-box">
      <div class="test-success__description-box-details details">
        <p class="details__title">Спасибо!</p>
        <p class="details__description">
          Ура! Ответы отправлены :) Мы ознакомимся с ним и напишем вам в
          телеграм в течение часа
        </p>
      </div>
    </div>
    <div class="test-success__image-box">
      <img
        src="@/assets/images/Saly-22.png"
        alt=""
        class="test-success__image-box-saly"
      />
    </div>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
.test-success {
  margin-top: 56px;
  width: 100%;
  display: flex;
  min-height: calc(100vh - 56px);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__description-box {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &__image-box {
    background-color: #5e99ff;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      background: #edeffa;
    }
    &-saly {
      max-width: 440px;

      @media (max-width: 1024px) {
        max-width: 450px;
        max-height: 290px;
      }
      @media (max-width: 768px) {
        max-width: 400px;
        max-height: 210px;
      }
      @media (max-width: 480px) {
        max-width: 280px;
        max-height: 200px;
      }
    }
  }
}
.details {
  max-width: 328px;
  padding: 20px 24px 24px 24px;
  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 8px;
  }
  &__description {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
