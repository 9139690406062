<template>
  <div class="test-exam">
    <div class="test-exam__description">
      <p class="test-exam__description-box-title">Тест</p>
      <p class="test-exam__description-box-description">
        Подтвердите ваш опыт ответами на вопросы
      </p>
    </div>

    <div
      class="test-exam__section-box section-box"
      v-if="props.questions.contains_code"
    >
      <p class="section-box__text">{{ props.questions.question }}</p>
      <div class="code-block">
        <code class="code" ref="codeBlock">{{ props.questions.code }}</code>
      </div>
      <Textarea
        v-model:value="form.candidate_answer"
        :auto-size="true"
        size="large"
        style="min-height: 64px"
        placeholder="Ваш ответ"
        @paste="handlePaste"
      />
      <div class="section-box__actions">
        <Button
          v-if="getQuestionNumber !== 0"
          size="large"
          class="section-box__actions-action"
          @click="previousQuestion"
          >Назад</Button
        >
        <Button
          class="section-box__actions-action btn"
          type="primary"
          size="large"
          @click="nextQuestion"
          >Продолжить</Button
        >
      </div>
    </div>

    <div class="test-exam__section-box section-box" v-else>
      <p class="section-box__text">{{ props.questions.question }}</p>
      <Textarea
        v-model:value="form.candidate_answer"
        :auto-size="true"
        size="large"
        style="min-height: 64px"
        placeholder="Ваш ответ"
        @paste="handlePaste"
      />
      <div class="section-box__actions">
        <Button
          v-if="getQuestionNumber !== 0"
          size="large"
          class="section-box__actions-action"
          @click="previousQuestion"
          >Назад</Button
        >
        <Button
          class="section-box__actions-action btn"
          type="primary"
          size="large"
          @click="nextQuestion"
          >Продолжить</Button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { defineProps } from "vue";
import { Textarea, Button } from "ant-design-vue";
const store = useStore();

const codeBlock = ref(null);
const lastActiveTimestamp = ref(Date.now());
let activeTimer;
let inactiveTimer;

const props = defineProps({
  questions: {
    type: Object,
    default: () => {},
  },
  getQuestionsCount: {
    type: Number,
    default: 0,
  },
  questionNumber: {
    type: Number,
    default: 0,
  },
});

const incrementQuestionNumber = () => {
  store.commit("incrementQuestionNumber");
};
const decrementQuestionNumber = () => {
  return store.commit("decrementQuestionNumber");
};
const chageTestStatus = () => {
  return store.commit("testCompleted");
};
const previousQuestion = () => {
  stopActiveTimer();
  stopInactiveTimer();
  decrementQuestionNumber();
};
const nextQuestion = async () => {
  form.time_spent = form.active_time + form.inactive_time;
  stopActiveTimer();
  stopInactiveTimer();
  incrementQuestionNumber();
  if (props.getQuestionsCount === props.questionNumber) {
    chageTestStatus();
  }
  await store.dispatch("postAnswers", form);
};

const getQuestionNumber = computed(() => {
  return store.getters["getQuestionNumber"];
});
const getUserId = computed(() => {
  return store.getters["getUserId"];
});

function updateActiveTime() {
  form.active_time += 1;
}

function updateInactiveTime() {
  form.inactive_time += 1;
}

function startActiveTimer() {
  activeTimer = setInterval(updateActiveTime, 1000);
}

function startInactiveTimer() {
  inactiveTimer = setInterval(updateInactiveTime, 1000);
}

function stopActiveTimer() {
  clearInterval(activeTimer);
}

function stopInactiveTimer() {
  clearInterval(inactiveTimer);
}

const handleVisibilityChange = () => {
  if (document.hidden) {
    stopActiveTimer();
    startInactiveTimer();
  } else {
    stopInactiveTimer();
    startActiveTimer();
    lastActiveTimestamp.value = Date.now();
  }
};

function handlePaste(event) {
  event.preventDefault();

  const clipboardData = (event.clipboardData || window.clipboardData).getData(
    "text"
  );

  const inputField = event.target;
  const start = inputField.selectionStart;
  const end = inputField.selectionEnd;
  const textBefore = form.candidate_answer.substring(0, start);
  const textAfter = form.candidate_answer.substring(end);

  form.candidate_answer = textBefore + clipboardData + textAfter;

  const cursorPos = start + clipboardData.length;
  inputField.setSelectionRange(cursorPos, cursorPos);

  form.pasted_texts.push(clipboardData);
}

const form = reactive({
  question_id: props.questions.id,
  candidate_answer: "",
  active_time: 0,
  inactive_time: 0,
  time_spent: 0,
  pasted_texts: [],
  developer_id: getUserId.value,
});

onMounted(() => {
  document.addEventListener("visibilitychange", handleVisibilityChange);
  startActiveTimer();
  if (codeBlock.value) {
    hljs.highlightBlock(codeBlock.value);
  }
});

onUnmounted(() => {
  stopActiveTimer();
  stopInactiveTimer();
});
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0;
}
.test-exam {
  max-width: 600px;
  width: 100%;
  color: #333;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px 20px 20px 20px;
  &__description-box {
    &-title {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      padding-bottom: 4px;
    }
    &-description {
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
.section-box {
  width: 100%;
  padding: 20px 24px 24px 24px;
  border-radius: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &__questions {
    &-form {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__actions {
    width: 100%;
    margin-top: 24px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    &-action {
      max-width: 160px;
      width: 100%;
    }
  }
  &__text {
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
.text-area {
  resize: none;
}

:deep(.ant-form-item-label) {
  & label {
    display: table-row;
  }
}

.code-block {
  background: #f8f9fd;
  border-radius: 16px;
  padding: 24px;
}
.code {
  word-wrap: break-word;
  white-space: break-spaces;
  background: #f8f9fd;
  line-height: 25px;
  padding: 0;
}
.btn {
  background: #5e99ff;
}
</style>
